var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"not-extended"},[_c('v-layout',{staticClass:"d-flex justify-center mu-7",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('p',{staticClass:"global-text6 mt-3 ml-5"},[_vm._v("Materia Asegurada")])]),_c('v-flex',{staticClass:"d-flex",attrs:{"lg6":"","xl5":""}},[_c('div',{staticClass:"global-search_field2 d-flex mt-2 mb-1"},[_c('v-col',{attrs:{"cols":"10"}},[_c('Input',{attrs:{"icon":"bi bi-search","size":"long","color":"#0077c8","model":_vm.search,"placeholder":'Buscar',"hide":true,"readonly":"false"},on:{"input":function($event){_vm.search = $event}}})],1),_c('PrimaryBtn',{staticClass:"ml-3 mt-2",attrs:{"label":"Buscar","color":"#0077c8"},nativeOn:{"click":function($event){return _vm.Buscar()}}})],1)]),_c('v-flex',{staticClass:"d-flex justify-end",attrs:{"lg6":"","xl5":""}},[_c('PrimaryBtn',{staticClass:"ml-3 mt-2",attrs:{"label":"Ingresar nuevo","color":"#0077c8"},nativeOn:{"click":function($event){return _vm.valueMateria.apply(null, arguments)}}})],1),_c('v-flex',{attrs:{"lg12":"","xl11":""}},[_c('SimpleTable',{attrs:{"loading":_vm.loadingTable,"items":_vm.materias,"headers":_vm.headers,"search":_vm.search},on:{"edit":_vm.editMateria,"delete":function($event){return _vm.openDelete(
            'Materia',
            2,
            $event.materia,
            $event.materia_descripcion,
            $event.ramo
          )}}})],1)],1),(_vm.state_modal)?_c('Modal',{attrs:{"datamodal":_vm.datamodal,"titulomodal":_vm.titulomodal,"idRamoModal":_vm.idRamoModal},on:{"cerrarmodalMateria":_vm.cerrarmodal}}):_vm._e(),(_vm.state_modal_update)?_c('ModalUpdate',{attrs:{"datamodal":_vm.datamodal,"titulomodal":_vm.titulomodal},on:{"cerrarmodalMateria":_vm.cerrarmodalUpdate}}):_vm._e(),(_vm.state_modal_delete)?_c('ModalDelete',{attrs:{"titulomodal":_vm.titulomodal,"step_id":_vm.step_id,"ids":_vm.ids,"dataDescription":_vm.dataDescription},on:{"cerrarmodalDelete":_vm.cerrarmodalDelete}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }