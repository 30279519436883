<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog" width="50%" height="10%">
      <v-card class="container-modal">
        <v-layout wrap class="pa-2">
          <v-flex>
            <div class="d-flex header_modal">
              <p class="global-span1 pa-3 mb-0 primary--text text--darken-4">
                Nueva {{ titulo }}
              </p>
              <v-spacer></v-spacer>
              <v-icon @click.self="closebtn(false)" class="global-icon7 mr-5"
                >mdi-close</v-icon
              >
            </div>
          </v-flex>

          <v-flex id="flex" lg8>
            <v-layout wrap class="ml-0 mt-15 px-2">
              <v-row>
                <v-alert v-if="showAler" shaped outlined :type="typeAlert">
                  {{ messageAlert }}
                </v-alert>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="TipoProd_desc"
                    :items="TipoProds"
                    item-text="tipo_producto_descripcion"
                    return-object
                    label="Tipo Producto"
                    outlined
                    :loading="loadingProductsType"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="materia"
                    name="nombre-comercial"
                    label="Materia Asegurada"
                    placeholder="Nuevo nombre de materia"
                    id="id_nombre_comercial"
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    outlined
                    v-model="codigo"
                    label="Código"
                    placeholder="Escriba el Código"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout @click="guardar()">
          <Button
            class="global-Btn4"
            label="Guardar"
            color="#0077c8"
            :loading="loading"
          />
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Button from "@/components/Button/Btn-Principal";
import { mapActions } from "vuex";

export default {
  props: ["form", "datamodal", "titulomodal", "idRamoModal"],
  components: {
    Button,
  },
  data() {
    return {
      dialog: true,
      loading: false,
      loadingProductsType: false,
      titulo: "",
      TipoProds: [],
      TipoProd_desc: "",
      codigo: "",

      dialog_input: {
        label: "Creación",
        text: "",
        disabled: false,
      },

      materia: "",
      showAler: false,
    };
  },
  created() {
    this.get_TipoProducto();
  },
  methods: {
    ...mapActions("NewProduct", ["CrearMateria", "getTipoProductos"]),
    closebtn(accion, message, color, icon) {
      this.$emit("cerrarmodalMateria", accion, message, color, icon);
    },
    async guardar() {
      this.loading = true;
      var data = {
        materia_descripcion: this.materia,
        tipo_producto: this.TipoProd_desc.tipo_producto,
        codigo: this.codigo,
      };
      await this.CrearMateria(data)
        .then((res) => {
          if (res.status == 200) {
            const messageAlert = res.data.data.lista_mensaje[0].mensaje;
            const typeAlert = "teal";
            const icon = "mdi-checkbox-marked-circle";
            this.closebtn(true, messageAlert, typeAlert, icon);
          }
          if (res.status == 422) {
            this.showAler = true;
            if (!res.data.error) {
              this.messageAlert = res.data.data.lista_mensaje[0].mensaje;
            } else {
              this.messageAlert = res.data.error;
            }
            this.typeAlert = "error";
            setTimeout(() => {
              this.showAler = false;
            }, 1500);
          }
        })
        .finally(() => (this.loading = false));
    },
    async get_TipoProducto() {
      this.loadingProductsType = true;
      const res = await this.getTipoProductos();
      this.TipoProds = res;
      this.loadingProductsType = false;
    },
  },
  mounted() {
    this.titulo = this.titulomodal;
    this.idRamo = this.idRamoModal;
  },
};
</script>

<style scoped>
@import "../../../assets/CSS/ServiceStyle.css";

#BackgroundSvgs {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

#flex {
  margin-left: -90%;
}

.container-modal {
  height: 340px;
  width: 880px;
  left: 0px;
  top: 0px;
  border-radius: 10px !important;
}

.header_modal {
  background: #223a6533;
}
</style>
