<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :items-per-page="itemsPage"
      :search="search"
      :page.sync="page"
      class="elevation-1"
      @page-count="pageCount = $event"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="primary" text fab @click="$emit('edit', item)" x-small>
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
        <v-btn color="error" text fab x-small @click="$emit('delete', item)">
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    paginacion: {
      type: Object,
      require: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: [Array],
      require: true,
    },
    footer: {
      type: Boolean,
      require: false,
    },
    itemsPage: {
      type: Number,
      default: 10,
    },
    search: {
      type: [String, Number],
      require: true,
    },
  },
  data: () => ({
    itemsPerPage: 10,
    page: 1,
    pageCount: 0,
    paginas: 12,
  }),
  methods: {
    next(e) {
      console.log("next ", e);
    },
    previous(e) {
      console.log("previous ", e);
    },
    input(e) {
      console.log("input", e);
      this.$emit("chagePage", e);
    },
  },
  mounted() {},
  watch: {
    paginacion(e) {
      this.paginas = e.paginas;
    },
  },
};
</script>

<style></style>
