import { render, staticRenderFns } from "./Modal-deletConf.vue?vue&type=template&id=e64b78c8&scoped=true&v-slot%3Aactivator=%7B%20on%2C%20attrs%20%7D"
import script from "./Modal-deletConf.vue?vue&type=script&lang=js"
export * from "./Modal-deletConf.vue?vue&type=script&lang=js"
import style0 from "./Modal-deletConf.vue?vue&type=style&index=0&id=e64b78c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e64b78c8",
  null
  
)

export default component.exports