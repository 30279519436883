<template>
  <v-container class="not-extended">
    <v-layout wrap class="d-flex justify-center mu-7">
      <v-flex xs12>
        <p class="global-text6 mt-3 ml-5">Materia Asegurada</p>
      </v-flex>
      <v-flex lg6 xl5 class="d-flex">
        <div class="global-search_field2 d-flex mt-2 mb-1">
          <v-col cols="10">
            <Input
              icon="bi bi-search"
              size="long"
              color="#0077c8"
              :model="search"
              :placeholder="'Buscar'"
              :hide="true"
              readonly="false"
              @input="search = $event"
            />
            <!-- v-mode.lazy="Buscar()"  -->
          </v-col>
          <PrimaryBtn
            v-on:click.native="Buscar()"
            label="Buscar"
            color="#0077c8"
            class="ml-3 mt-2"
          />
        </div>
      </v-flex>
      <v-flex lg6 xl5 class="d-flex justify-end">
        <PrimaryBtn
          label="Ingresar nuevo"
          v-on:click.native="valueMateria"
          color="#0077c8"
          class="ml-3 mt-2"
        />
      </v-flex>
      <v-flex lg12 xl11>
        <SimpleTable
          :loading="loadingTable"
          :items="materias"
          :headers="headers"
          :search="search"
          @edit="editMateria"
          @delete="
            openDelete(
              'Materia',
              2,
              $event.materia,
              $event.materia_descripcion,
              $event.ramo
            )
          "
        />
      </v-flex>
    </v-layout>
    <Modal
      v-if="state_modal"
      @cerrarmodalMateria="cerrarmodal"
      :datamodal="datamodal"
      :titulomodal="titulomodal"
      :idRamoModal="idRamoModal"
    />
    <ModalUpdate
      v-if="state_modal_update"
      @cerrarmodalMateria="cerrarmodalUpdate"
      :datamodal="datamodal"
      :titulomodal="titulomodal"
    />
    <ModalDelete
      v-if="state_modal_delete"
      @cerrarmodalDelete="cerrarmodalDelete"
      :titulomodal="titulomodal"
      :step_id="step_id"
      :ids="ids"
      :dataDescription="dataDescription"
    />
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import Input from "@/components/Inputs/Input";
import PrimaryBtn from "@/components/Button/Btn-Principal";
import SimpleTable from "@/components/Tables/Table";
import Modal from "@/components/Modal/ModalProduct/Modal-MateriaNegocio";
import ModalUpdate from "@/components/Modal/ModalProduct/Modal-MateriaNegocioUpdate";
import ModalDelete from "@/components/Modal/ModalAlert/Modal-deletConf";

export default {
  name: "MateriaAseguradaCRUD",
  components: {
    SimpleTable,
    Input,
    PrimaryBtn,
    Modal,
    ModalUpdate,
    ModalDelete,
  },
  data: () => ({
    text_search: {
      placeholder: "Buscar",
      text: "",
      search: "test",
      icon: "mdi-magnify",
    },
    search: "",
    state_modal: false,
    state_modal_update: false,
    state_modal_delete: false,
    datamodal: [],
    titulomodal: "Materia",
    loadingTable: false,
    idRamoModal: "ejemplo",
    ramoId: "621385b3efdabd00135d2b8e",
    headers: [
      { text: "Materia", value: "materia_descripcion", align: "left" },
      { text: "Código", value: "codigo", align: "center" },
      {
        text: "Tipo Producto",
        value: "tipo_producto_descripcion",
        align: "lect",
      },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    materias: [],
  }),

  methods: {
    ...mapActions("NewProduct", ["getMateriaAll"]),
    Buscar() {
      this.search = this.text_search.text.replace(/[^A-Z0-9]/gi, "");
    },

    async getMaterias() {
      this.loadingTable = true;
      try {
        const res = await this.getMateriaAll();
        this.materias = res;
      } catch (error) {
        console.log(error);
      }
      this.loadingTable = false;
    },

    openModalMateria(tipo) {
      this.titulomodal = tipo;
      this.idRamoModal = this.ramoId;
      this.state_modal = true;
    },

    valueMateria() {
      if (this.ramoId == "") {
        var texto =
          "Debe seleccionar un ramo para agreagar una nueva materia asegurada";
        console.log("Sin Ramo");
        this.snackbar = true;
        this.color_message = "error";
        this.text_Menssage = texto;
        this.icon = "mdi-cloud-alert";
      } else {
        this.openModalMateria("Materia", this.ramoId);
      }
    },

    openDelete(tipo, step, id, data) {
      this.titulomodal = tipo;
      this.step_id = step;
      this.ids = id;
      this.dataDescription = data;
      this.state_modal_delete = true;
    },

    cerrarmodal(accion) {
      if (accion == false) {
        this.state_modal = false;
        this.datamodal = [];
      } else {
        this.state_modal = false;
        this.datamodal = [];
        this.getMaterias();
      }
    },

    cerrarmodalUpdate(accion) {
      if (accion == false) {
        this.state_modal_update = false;
        this.datamodal = [];
      } else {
        this.state_modal_update = false;
        this.datamodal = [];
        this.getMaterias();
      }
    },

    cerrarmodalDelete(action, message, color, icon) {
      if (action == false) {
        this.state_modal_delete = false;
      } else {
        this.state_modal_delete = false;
        this.snackbar = true;
        this.color_message = color;
        this.text_Menssage = message;
        this.icon = icon;
        this.getMaterias();
      }
    },

    editMateria(item) {
      this.state_modal_update = true;
      this.datamodal = item;
    },
  },

  computed: {},

  mounted() {
    this.getMaterias();
  },
};
</script>
