<template v-slot:activator="{ on, attrs }">
  <div class="text-center">
    <v-dialog persistent v-model="dialog" max-width="360">
      <v-card>
        <v-card-title class="text-h5 Modla_deletHead">
          Eliminar {{ titulo }}
        </v-card-title>

        <v-card-text> Esta seguro de eliminar {{ tituloData }}? </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="grey" @click="closebtn(false)"> cerrar </v-btn>

          <v-btn color="error" text @click="Delete()" :loading="loading">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Button from "@/components/Button/Btn-Principal";
import { mapActions } from "vuex";

export default {
  props: [
    "form",
    "datamodal",
    "titulomodal",
    "step_id",
    "ids",
    "dataDescription",
  ],
  components: {
    // Button,
  },
  data() {
    return {
      dialog: true,
      titulo: "",
      ramo: "",
      showAler: false,
      ste: "",
      id: "",
      tituloData: "",
      loading: false,

      dialog_input: {
        label: "Creación",
        text: "",
        disabled: false,
      },
    };
  },
  methods: {
    ...mapActions("NewProduct", [
      "CreateRamo",
      "deleteRamos",
      "deletePaises",
      "deleteMateria",
      "deleteTipoProd",
      "deleteMoneda",
      "deleteCobertura",
      "deletePlan",
      "DeleteSegment",
      "deleteVigencia",
      "deletePoliza",
      "deleteTipoCobertura",
      "deleteDocumentoAnexo",
      "deleteDocumentoEmision",
      "deleteTipoContrat",
      "deleteFinanciera",
      "DeleteProductUse",
      "deleteColor",
      "getMoneda",
    ]),

    closebtn(action, message, color, icon) {
      this.$emit("cerrarmodalDelete", action, message, color, icon);
      this.dialog = false;
    },
    async Delete() {
      this.loading = true;
      switch (this.ste) {
        case 1: {
          await this.deleteRamos(this.id)
            .then((response) => {
              const menssage = response.info;
              const icon = "mdi-checkbox-marked-circle";
              const color = "success";
              this.closebtn(true, menssage, color, icon);
            })
            .catch((error) => {
              const menssage = error.result.message || error.info;
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            });
          break;
        }
        case 2: {
          await this.deleteMateria(this.id).then((resp) => {
            if (resp.status == 200) {
              const menssage = resp.data.data.lista_mensaje[0].mensaje;
              const icon = "mdi-checkbox-marked-circle";
              const color = "success";
              this.closebtn(true, menssage, color, icon);
            } else {
              const menssage = "Error al eliminar Materia";
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            }
          });
          break;
        }
        case 3: {
          await this.deleteTipoProd(this.id)
            .then((resp) => {
              const menssage =
                resp.data?.data?.lista_mensaje?.[0]?.mensaje ?? resp.info;
              const icon = "mdi-checkbox-marked-circle";
              const color = "success";
              this.closebtn(true, menssage, color, icon);
            })
            .catch((error) => {
              const menssage = error.info;
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            });
          break;
        }
        case 4: {
          console.log(this.ste);
          break;
        }
        case 5: {
          await this.deletePlan(this.id).then((resp) => {
            if (resp.status == 200) {
              const menssage = resp.data.data.lista_mensaje[0].mensaje;
              const icon = "mdi-checkbox-marked-circle";
              const color = "success";
              this.closebtn(true, menssage, color, icon);
            } else {
              const menssage = "error al eliminar Plan";
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            }
          });
          break;
        }
        case 6: {
          await this.deleteVigencia(this.id)
            .then((response) => {
              const menssage = response.info;
              const icon = "mdi-checkbox-marked-circle";
              const color = "success";
              this.closebtn(true, menssage, color, icon);
            })
            .catch((error) => {
              const menssage = error.info;
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            });
          break;
        }
        case 7: {
          await this.deleteDocumentoAnexo(this.id).then((response) => {
            const menssage = response.info;
            const icon = "mdi-checkbox-marked-circle";
            const color = "success";
            this.closebtn(true, menssage, color, icon);
          });
          break;
        }
        case 8: {
          await this.deleteDocumentoEmision(this.id).then((resp) => {
            const menssage = resp.info;
            const icon = "mdi-checkbox-marked-circle";
            const color = "success";
            this.closebtn(true, menssage, color, icon);
          });
          break;
        }
        case 9: {
          await this.deletePaises(this.id).then((resp) => {
            console.log(resp);
            if (resp == "Se ha eliminado el País satisfactoriamente!") {
              const menssage = resp;
              const icon = "mdi-checkbox-marked-circle";
              const color = "success";
              this.closebtn(true, menssage, color, icon);
            } else {
              const menssage = "error al eliminar el país";
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            }
          });
          break;
        }
        case 10: {
          await this.deleteMoneda(this.id).then((resp) => {
            if (resp == "Se ha eliminado la moneda satisfactoriamente!") {
              const menssage = resp;
              const icon = "mdi-checkbox-marked-circle";
              const color = "success";
              this.closebtn(true, menssage, color, icon);
            } else {
              const menssage = "error al eliminar el país";
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            }
          });
          break;
        }
        case 11: {
          await this.deleteCobertura(this.id).then((resp) => {
            if (resp.status == 200) {
              const menssage = resp.data.data.lista_mensaje[0].mensaje;
              const icon = "mdi-checkbox-marked-circle";
              const color = "success";
              this.closebtn(true, menssage, color, icon);
            } else {
              const menssage = resp.data.data.lista_mensaje[0].mensaje;
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            }
          });
          break;
        }
        case 13: {
          await this.deletePoliza(this.id).then((resp) => {
            if (resp.status == 200) {
              const menssage = resp.data.data.lista_mensaje[0].mensaje;
              const icon = "mdi-checkbox-marked-circle";
              const color = "success";
              this.closebtn(true, menssage, color, icon);
            } else {
              const menssage = "error al eliminar el país";
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            }
          });
          break;
        }
        case 14: {
          await this.deleteTipoCobertura(this.id).then((resp) => {
            if (resp.status == 200) {
              const menssage = resp.data.data.lista_mensaje[0].mensaje;
              const icon = "mdi-checkbox-marked-circle";
              const color = "success";
              this.closebtn(true, menssage, color, icon);
            } else {
              const menssage = "error al eliminar el país";
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            }
          });
          break;
        }
        case 16: {
          await this.deleteTipoContrat(this.id).then((resp) => {
            if (resp.status == 200) {
              const menssage = resp.data.data.lista_mensaje[0].mensaje;
              const icon = "mdi-checkbox-marked-circle";
              const color = "success";
              this.closebtn(true, menssage, color, icon);
            } else {
              const menssage = "error al eliminar el país";
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            }
          });
          break;
        }
        case 17: {
          await this.deleteFinanciera(this.id).then((resp) => {
            if (resp.status == 200) {
              const menssage = resp.data.data.lista_mensaje[0].mensaje;
              const icon = "mdi-checkbox-marked-circle";
              const color = "success";
              this.closebtn(true, menssage, color, icon);
            } else {
              const menssage = "error al eliminar el país";
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            }
          });
          break;
        }

        case 30: {
          let message = "",
            icon = "",
            color = "";
          await this.DeleteSegment(this.id)
            .then((response) => {
              message = response.info;
              color = "success";
              icon = "mdi-checkbox-marked-circle";
            })
            .catch((error) => {
              message = error.result?.message || error.info;
              color = "error";
              icon = "mdi-cloud-alert";
            })
            .finally(() => {
              this.closebtn(true, message, color, icon);
            });
          break;
        }
        case 31: {
          let message = "",
            icon = "",
            color = "";
          await this.DeleteProductUse(this.id)
            .then((response) => {
              message = response.info;
              color = "success";
              icon = "mdi-checkbox-marked-circle";
            })
            .catch((error) => {
              message = error.info;
              color = "error";
              icon = "mdi-cloud-alert";
            })
            .finally(() => {
              this.closebtn(true, message, color, icon);
            });
          break;
        }
        case 32: {
          await this.deleteColor(this.id).then((resp) => {
            if (resp.status == 200) {
              const menssage = resp.data.data.lista_mensaje[0].mensaje;
              const icon = "mdi-checkbox-marked-circle";
              const color = "success";
              this.closebtn(true, menssage, color, icon);
            } else {
              const menssage = "error al eliminar color";
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            }
          });
          break;
        }
        default:
          break;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.titulo = this.titulomodal;
    this.ste = this.step_id;
    this.id = this.ids;
    this.tituloData = this.dataDescription;
  },
};
</script>

<style scoped>
@import "../../../assets/CSS/ServiceStyle.css";
#BackgroundSvgs {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

#flex {
  margin-left: -90%;
}

.container-modal {
  height: 240px;
  width: 880px;
  left: 0px;
  top: 0px;
  border-radius: 10px !important;
}

.btn-Delet-cancel {
  margin-left: 13em;
}
.Modla_deletHead {
  word-break: break-word;
}
</style>
